import { v4 as uuidv4 } from 'uuid';
import { FacialAnalysis } from '../services/hooks/session/useSession.types';

/**
 * Generates an array of FacialAnalysis objects with sequential segments
 * @param length The number of FacialAnalysis objects to generate
 * @param sessionId Optional sessionId to use for all objects (defaults to a new UUID)
 * @returns Array of FacialAnalysis objects
 */
export const generateFacialAnalyses = (length: number, sessionId?: string): FacialAnalysis[] => {
  const defaultSessionId = sessionId || uuidv4();
  const now = new Date().toISOString();

  const ranges = {
    blinkRate: {
      min: 5,    // Extreme sadness min
      max: 50,   // Extreme fear/anxiety max
      normal: { min: 15, max: 20 }
    },
    smileRate: {
      min: 0,    // Extreme anger/sadness min
      max: 15,   // Extreme happiness max
      normal: { min: 4, max: 8 }
    },
    eyeContact: {
      min: 5,    // Extreme fear min
      max: 80,   // Extreme happiness/excitement max
      normal: { min: 40, max: 60 }
    }
  };

  const getRandomInRange = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  const generateValueWithinRanges = (range: typeof ranges.blinkRate) => {
    if (Math.random() < 0.7) {
      return getRandomInRange(range.normal.min, range.normal.max);
    } else {
      const useUpperExtreme = Math.random() < 0.5;
      return useUpperExtreme
        ? getRandomInRange(range.normal.max, range.max)
        : getRandomInRange(range.min, range.normal.min);
    }
  };

  return Array.from({ length }, (_, index) => {
    const segment = index;
    const startTime = new Date(Date.now() - (length - segment) * 60000).toISOString();
    const endTime = new Date(Date.now() - (length - segment - 1) * 60000).toISOString();

    return {
      id: uuidv4(),
      segment,
      sessionId: defaultSessionId,
      startTime,
      endTime,
      transcript: `Transcript for segment ${segment}`,
      blinkRate: generateValueWithinRanges(ranges.blinkRate),
      smileRate: generateValueWithinRanges(ranges.smileRate),
      eyeContact: generateValueWithinRanges(ranges.eyeContact),
      createdAt: now,
      updatedAt: now,
      deletedAt: null
    };
  });
};
