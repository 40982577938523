import { v4 as uuidv4 } from 'uuid';
import { AudioAnalysis } from '../services/hooks/session/useSession.types';

/**
 * Generates an array of AudioAnalysis objects with sequential segments
 * @param length The number of AudioAnalysis objects to generate
 * @param sessionId Optional sessionId to use for all objects (defaults to a new UUID)
 * @returns Array of AudioAnalysis objects
 */
export const generateAudioAnalyses = (length: number, sessionId?: string): AudioAnalysis[] => {
  const defaultSessionId = sessionId || uuidv4();
  const now = new Date().toISOString();

  const ranges = {
    speakingRate: {
      min: 80,   // Extreme sadness min
      max: 220,  // Extreme excitement/happiness max
      normal: { min: 120, max: 150 }
    },
    averagePitch: {
      min: 80,   // Extreme sadness min
      max: 300,  // Extreme fear/anxiety max
      normal: { min: 100, max: 150 }
    },
    loudness: {
      min: 30,   // Extreme sadness min
      max: 100,  // Extreme anger max
      normal: { min: 50, max: 70 }
    }
  };

  const getRandomInRange = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  const generateValueWithinRanges = (range: typeof ranges.speakingRate) => {
    if (Math.random() < 0.7) {
      return getRandomInRange(range.normal.min, range.normal.max);
    } else {
      const useUpperExtreme = Math.random() < 0.5;
      return useUpperExtreme
        ? getRandomInRange(range.normal.max, range.max)
        : getRandomInRange(range.min, range.normal.min);
    }
  };

  return Array.from({ length }, (_, index) => {
    const segment = index ; // Segments start from 0
    const startTime = new Date(Date.now() - (length - segment) * 60000).toISOString(); // Each segment is 1 minute apart
    const endTime = new Date(Date.now() - (length - segment - 1) * 60000).toISOString();
    
    return {
      id: uuidv4(),
      segment,
      sessionId: defaultSessionId,
      startTime,
      endTime,
      transcript: `Transcript for segment ${segment}`,
      speakingRate: generateValueWithinRanges(ranges.speakingRate),
      averagePitch: generateValueWithinRanges(ranges.averagePitch),
      loudness: generateValueWithinRanges(ranges.loudness),
      speechDuration: Math.random() * 45 + 15, // Random speech duration between 15 and 60 seconds
      silenceDuration: Math.random() * 15, // Random silence duration between 0 and 15 seconds
      createdAt: now,
      updatedAt: now,
      deletedAt: null
    };
  });
};
