// src/components/VideoPlayer.tsx

import React, { useState } from 'react';
import { FaPlay, FaPause, FaVolumeMute, FaVolumeUp, FaExpand } from 'react-icons/fa';
import './VideoPlayer.scss';

interface VideoPlayerProps {
  src: string;
  title?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ src, title }) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(videoRef.current.muted);
    }
  };

  const handleFullscreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      }
    }
  };

  return (
    <div className='video-container'>
      {title && (
        <div className='absolute top-0 left-0 w-full bg-opacity-50 bg-black text-white p-2 text-center'>
          {title}
        </div>
      )}
      <video ref={videoRef} className='w-full h-full' controls={false}>
        <source src={src} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      <div className='video-controls'>
        <button onClick={togglePlayPause} className='p-2'>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        <button onClick={toggleMute} className='p-2'>
          {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
        </button>
        <button onClick={handleFullscreen} className='p-2'>
          <FaExpand />
        </button>
      </div>
    </div>
  );
};

export default VideoPlayer;
