import { TextAnalysis } from '../services/hooks/session/useSession.types';

export const calculateOverallMood = (textAnalyses?: TextAnalysis[]): string => {
  if (!textAnalyses || textAnalyses.length === 0) return '-';

  const positiveEmotions = ['Highly Positive', 'Mildly Positive', 'Positive'];
  const negativeEmotions = ['Highly Negative', 'Mildly Negative', 'Negative'];

  const sentimentCounts = textAnalyses.reduce((acc: { [key: string]: number }, analysis: TextAnalysis) => {
    if (analysis.sentiment) {
      acc[analysis.sentiment] = (acc[analysis.sentiment] || 0) + 1;
    }
    return acc;
  }, {});

  const positivesCount = Object.entries(sentimentCounts)
    .filter(([sentiment]) => positiveEmotions.includes(sentiment))
    .reduce((sum: number, [, count]: [string, unknown]) => sum + (count as number), 0);

  const negativesCount = Object.entries(sentimentCounts)
    .filter(([sentiment]) => negativeEmotions.includes(sentiment))
    .reduce((sum: number, [, count]: [string, unknown]) => sum + (count as number), 0);

  const neutralCount = sentimentCounts['Neutral'] || 0;

  // Calculate the net sentiment after positive and negative cancel each other
  const netSentiment = Math.abs(positivesCount - negativesCount);
  const dominantNonNeutral = positivesCount > negativesCount ? 'Positive' : 'Negative';

  // If neutral count is greater than the remaining sentiment after cancellation
  if (neutralCount > netSentiment) {
    return 'Neutral';
  }

  // If there's a remaining sentiment after cancellation, return that
  if (netSentiment > 0) {
    return dominantNonNeutral;
  }

  // If everything cancels out (netSentiment = 0 and neutralCount = 0)
  return 'Neutral';
};
