import React, { useCallback, useState, useEffect } from 'react';
import { Column } from 'react-table';
import { useParams } from 'react-router';

import { Table } from '../../components/table/Table';
import RecordingLinkCell from './recording-cell/RecordingCell';

import useAxios from '../../services/axios/useAxios';
import CopyCell from './copy-cell/CopyCell';
import DateCell from './date-cell/DateCell';
import ActionCell from './action-cell/ActionCell';
import { useKeycloak } from '@react-keycloak/web';

interface SessionsResponse {
  totalRecords: number;
  sessions: Session[];
}

interface Session {
  sessionId: string;
  userId: string;
  name: string;
  email: string;
  comment: string;
  recordingLink?: string;
  recordedDate?: string;
}

export default function Sessions() {
  const sessionColumns: Column<Session | any>[] = [
    {
      Header: 'Recorded at',
      accessor: 'recordedDate',
      Cell: DateCell,
    },
    {
      Header: 'Session ID',
      accessor: 'sessionId',
      Cell: CopyCell,
    },
    // {
    //   Header: 'User ID',
    //   accessor: 'userId',
    // },
    {
      Header: 'Name',
      accessor: 'name',
    },
    // {
    //   //   Header: 'Email',
    //   //   accessor: 'email',
    //   // },
    {
      Header: 'Comment',
      accessor: 'comment',
    },
    {
      Header: 'Preview',
      accessor: 'previewSession',
      Cell: RecordingLinkCell,
    },
    {
      Header: 'Link',
      accessor: 'recordingLink',
      Cell: CopyCell,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <ActionCell row={row} onDelete={() => handleDelete(row.original.sessionId)} />
      ),
    },
  ];

  const {
    keycloak: { authenticated },
  } = useKeycloak();

  const [sessionData, setSessionData] = useState<Session[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [refreshKey, setRefreshKey] = useState<number>(0);

  const axios = useAxios();

  const { userId } = useParams();

  const fetchData = useCallback(
    async (params?: any) => {
      if (!authenticated) return;
      try {
        setIsLoading(true);
        const queryParams = new URLSearchParams();
        queryParams.append('rowsPerPage', params?.rowsPerPage || 25);
        queryParams.append('page', params?.page);
        if (userId) {
          queryParams.append('user', userId);
        }

        const response: SessionsResponse = await axios.request({
          method: 'GET',
          url: `${process.env.REACT_APP_CHAT_API}/sessions?${queryParams.toString()}`,
        });
        setSessionData(response.sessions || []);
        setTotalItems(response.totalRecords || 0);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [axios, userId, authenticated],
  );

  const handleDelete = useCallback((deletedSessionId: string) => {
    setRefreshKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <Table
      columns={sessionColumns}
      data={sessionData}
      totalRecords={totalItems}
      fetchFunction={fetchData}
      isLoading={loading}
      refreshKey={refreshKey}
    />
  );
}
