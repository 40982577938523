import React, { useState } from 'react';
import VideoModal from '../../../../components/video-modal/VideoModal';
import { FaVideo, FaComments } from 'react-icons/fa';

const RecordingLinkCell: React.FC<any> = ({ row }) => {
  console.log(row);
  if (!row.values.recordingLink) {
    return <>-</>;
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          openModal();
        }}
        className='text-blue-600 hover:text-blue-800 visited:text-purple-600 flex items-center'
      >
        {row.original.source === 'zoom' ? (
          <FaVideo className='mr-1' />
        ) : row.original.source === 'chat' ? (
          <FaComments className='mr-1' />
        ) : null}
        {row.values.recordingLink ? 'View Recording' : '-'}
      </button>
      <VideoModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title={`${row.original.name + ' ' + row.original.recordedDate}`}
        videoLink={row.values.recordingLink}
      />
    </>
  );
};

export default RecordingLinkCell;
