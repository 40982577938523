import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ZoomMtg } from '@zoom/meetingsdk';
import classNames from 'classnames';
import * as faceapi from 'face-api.js';
import { useParams } from 'react-router';
import useZoom from '../../services/hooks/zoom/useZoom';
import { useKeycloak } from '@react-keycloak/web';
import { KeycloakProfile } from 'keycloak-js';

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

import './index.css';

const ZoomMeeting = () => {
  const { id, role = 'patient' } = useParams();

  const [user, setUser] = useState<KeycloakProfile>();
  const [signature, setSignature] = useState<string>();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [faceDetected, setFaceDetected] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const { keycloak } = useKeycloak();

  const { generateSignature } = useZoom();

  const handleStartRecording = useCallback(async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream, {
      // mimeType: 'video/webm; codecs=vp8',
    });

    mediaRecorderRef.current.start();

    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, []);

  const sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY!;
  console.log(sdkKey);
  const leaveUrl = `${process.env.REACT_APP_ZOOM_LEAVE_URL!}/${role}/calendar`;
  let faceInterval: NodeJS.Timer;
  const joinMeeting = async () => {
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      patchJsMedia: true,
      leaveOnPageUnload: true,
      success: (zoomInitData: any) => {
        ZoomMtg.join({
          signature: signature!,
          customerKey: user?.id,
          sdkKey: sdkKey,
          meetingNumber: id!,
          userName: user?.id!,
          userEmail: user?.email,
          success: (joinData: any) => {},
          error: (error: any) => {
            console.log(error);
          },
        });
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  };

  const init = async () => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + '/models';

      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
      ]).then(() => {
        faceInterval = setInterval(async () => {
          if (!videoRef.current) {
            return;
          }
          const detections = await faceapi.detectAllFaces(
            videoRef.current,
            new faceapi.TinyFaceDetectorOptions(),
          );
          setFaceDetected(!!detections.length);
        }, 500);
      });
    };
    loadModels();
    handleStartRecording();
    const rs = await generateSignature({ role: 1, zoomId: id! });
    setSignature(rs?.signature);
  };

  useEffect(() => {
    if (!keycloak?.authenticated) {
      return;
    }
    keycloak?.loadUserProfile().then(async (user) => {
      setUser(user);
    });
  }, [keycloak?.authenticated]);

  React.useEffect(() => {
    init();
    return () => {
      clearInterval(faceInterval);
    };
  }, []);

  React.useEffect(() => {
    if (!user || !signature) {
      return;
    }
    joinMeeting();
  }, [user, signature]);

  return (
    <div
      className={classNames('w-full h-full fixed border-solid border-[10px]', {
        'border-green-500': faceDetected,
        'border-red-500': !faceDetected,
      })}
    >
      <div className='relative h-[150px] w-[200px]'>
        <video ref={videoRef} autoPlay muted width='0' />
      </div>
    </div>
  );
};

export default ZoomMeeting;
