import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import useAxios from '../../../../services/axios/useAxios';

interface ActionCellProps {
  row: any;
  onDelete: () => void; // New prop for handling deletion
}

export const ActionCell: React.FC<ActionCellProps> = ({ row, onDelete }) => {
  console.log(row);
  const navigate = useNavigate();
  const axios = useAxios();
  const [showConfirm, setShowConfirm] = useState(false);

  const handleViewSessions = useCallback(() => {
    navigate(`/sessions/${row.original.sessionId}`);
  }, [navigate, row.original.sessionId]);

  const handleDelete = useCallback(async () => {
    try {
      await axios?.request({
        url: `${process.env.REACT_APP_CHAT_API}/sessions/${row.original.sessionId}`,
        method: 'DELETE',
      });
      toast.success('Session deleted successfully');
      onDelete(); // Call the onDelete prop to refresh the table
    } catch (error) {
      toast.error('Failed to delete session');
    }
    setShowConfirm(false);
  }, [axios, row.original.sessionId, onDelete]);

  return (
    <div className='flex items-center space-x-2'>
      <button
        onClick={handleViewSessions}
        className='text-blue-500 hover:text-blue-700'
        title='View Details'
      >
        <FaEye size={20} />
      </button>
      <button
        onClick={() => setShowConfirm(true)}
        className='text-red-500 hover:text-red-700'
        title='Delete Session'
      >
        <FaTrash size={20} />
      </button>
      {showConfirm && (
        <div className='fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center'>
          <div className='bg-white p-5 rounded-lg shadow-lg'>
            <h2 className='text-lg font-bold mb-4'>Confirm Deletion</h2>
            <p>Are you sure you want to delete this session?</p>
            <div className='mt-4 flex justify-end space-x-2'>
              <button
                onClick={() => setShowConfirm(false)}
                className='bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded'
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionCell;
