import { useCallback, useEffect, useState } from 'react';

interface PaginationHook {
  previousPage: () => void;
  nextPage: () => void;
  goToPage: (pageNumber: number) => void;
  totalPages: number;
  currentPage: number;
}

export const usePagination = (
  totalItems: number,
  rowsPerPage: number = 25,
  fetchFunction: (params: any) => void,
  refreshKey?: number,
): PaginationHook => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const nextPage = useCallback(() => {
    setCurrentPage((current) => Math.min(current + 1, totalPages));
  }, [totalPages]);

  const previousPage = useCallback(() => {
    setCurrentPage((current) => Math.max(current - 1, 1));
  }, []);

  const goToPage = useCallback(
    (pageNumber: number) => {
      const newPage = Math.max(1, Math.min(pageNumber, totalPages));
      setCurrentPage(newPage);
    },
    [totalPages],
  );

  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / rowsPerPage));
  }, [totalItems, rowsPerPage]);

  useEffect(() => {
    try {
      fetchFunction({
        rowsPerPage,
        page: currentPage,
      });
    } catch (e) {
      console.error(e);
    }
  }, [currentPage, rowsPerPage, fetchFunction, refreshKey]);

  return { nextPage, previousPage, goToPage, totalPages, currentPage };
};
