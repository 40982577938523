import React from 'react';

interface LoadingScreenProps {
  title?: string;
  subtitle?: string;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ 
  title = 'Loading...',
  subtitle = 'Please wait while we prepare your data'
}) => {
  return (
    <div className="fixed inset-0 bg-white bg-opacity-80 z-50 flex items-center justify-center">
      <div className="text-center">
        <div className="inline-block animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500 mb-4"></div>
        <div className="text-xl font-semibold text-gray-700">{title}</div>
        <div className="text-sm text-gray-500 mt-2">{subtitle}</div>
      </div>
    </div>
  );
};

export default LoadingScreen;
