import React from 'react';
import dayjs from 'dayjs';

const DateCell: React.FC<any> = ({ row }) => {
  const date = dayjs(new Date(row.values.recordedDate)).format('MM-DD-YYYY HH:mm');

  return <span>{date}</span>;
};

export default DateCell;
