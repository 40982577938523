import { TextAnalysis } from "../services/hooks/session/useSession.types";

interface EVIResult {
  index: number;
  details: {
    avgIntensityChange: number;
    emotionChanges: number;
    timeWeightedChange: number;
  };
}

export const calculateEmotionalVariability = (analyses: TextAnalysis[]): EVIResult => {
  if (!analyses || analyses.length < 2)
    return {
      index: 0,
      details: {
        avgIntensityChange: 0,
        emotionChanges: 0,
        timeWeightedChange: 0,
      },
    };

  // Calculate intensity changes
  const intensityChanges = analyses.slice(1).map((analysis, index) => {
    return Math.abs(analysis.emotionIntensity - analyses[index].emotionIntensity);
  });

  // Calculate emotion type changes
  const emotionChanges = analyses.slice(1).map((analysis, index) => {
    return analysis.primaryEmotion !== analyses[index].primaryEmotion ? 1 : 0;
  });

  // Calculate time-weighted changes
  const timeWeightedChanges = analyses.slice(1).map((analysis, index) => {
    const timeGap =
      (new Date(analysis?.startTime).getTime() - new Date(analyses[index]?.startTime).getTime()) /
      1000; // in seconds
    return {
      intensityChange: intensityChanges[index] / (timeGap || 1),
      emotionChange: emotionChanges[index] / (timeGap || 1),
    };
  });

  // Calculate averages
  const avgIntensityChange = intensityChanges.reduce((a, b) => a + b, 0) / intensityChanges.length;
  const emotionChangeCount = emotionChanges.reduce((a, b) => (a + b) as 0 | 1, 0);
  const avgTimeWeightedIntensityChange =
    timeWeightedChanges.reduce((acc, curr) => acc + curr.intensityChange, 0) /
    timeWeightedChanges.length;

  // Calculate overall variability index (0-100 scale)
  const intensityFactor = Math.min(avgIntensityChange / 20, 1); // Normalize by assuming max 20 points change
  const emotionChangeFactor = Math.min(emotionChangeCount / analyses.length, 1);
  const timeWeightedFactor = Math.min(avgTimeWeightedIntensityChange, 1);

  const variabilityIndex = Math.round(
    ((intensityFactor + emotionChangeFactor + timeWeightedFactor) / 3) * 100,
  );

  return {
    index: variabilityIndex,
    details: {
      avgIntensityChange: Math.round(avgIntensityChange * 10) / 10,
      emotionChanges: emotionChangeCount,
      timeWeightedChange: Math.round(avgTimeWeightedIntensityChange * 10) / 10,
    },
  };
};
