import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faTimes } from '@fortawesome/free-solid-svg-icons';
import Chat from './Chat';

interface ChatWidgetProps {
  sessionId: string;
  onSendMessage: (message: string, history: Record<'user' | 'bot', string>) => Promise<any>;
}

const ChatWidget: React.FC<ChatWidgetProps> = ({ sessionId, onSendMessage }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {/* Floating Chat Button */}
      <button
        onClick={() => setIsOpen(true)}
        className={`fixed bottom-6 right-6 w-14 h-14 rounded-full bg-blue-500 text-white shadow-lg 
          hover:bg-blue-600 transition-all duration-200 flex items-center justify-center z-50
          ${isOpen ? 'scale-0' : 'scale-100'}`}
      >
        <FontAwesomeIcon icon={faComments} className="text-xl" />
      </button>

      {/* Chat Window */}
      <div
        className={`fixed bottom-0 right-0 w-96 transition-all duration-300 z-50 transform
          ${isOpen ? 'translate-y-0' : 'translate-y-full'}`}
      >
        <div className="flex flex-col h-[600px] mx-4 rounded-t-xl shadow-xl bg-white overflow-hidden">
          {/* Chat Header */}
          <div className="flex items-center justify-between p-4 bg-blue-500 rounded-t-xl">
            <span className="text-lg font-semibold text-white">Chat Assistant</span>
            <button
              onClick={() => setIsOpen(false)}
              className="text-white hover:text-gray-200 transition-colors"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>

          {/* Chat Content */}
          <div className="flex-1 overflow-hidden">
            <Chat
              sessionId={sessionId}
              onSendMessage={onSendMessage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatWidget;
