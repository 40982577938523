import { useCallback, useState } from 'react';
import useAxios from '../../axios/useAxios';
import { useKeycloak } from '@react-keycloak/web';
import { UserProfileInterface } from './userProfile.types';
import { SessionDTO } from '../session/useSession.types';
import { generateAudioAnalyses } from '../../../utils/generateAudioAnalysis';
import { generateFacialAnalyses } from '../../../utils/generateFacialAnalysis';

interface SessionsResponse {
  sessions: SessionDTO[];
  totalRecords: number;
}

const useUserProfile = () => {
  const axios = useAxios();
  const { keycloak } = useKeycloak();
  const authenticated = keycloak?.authenticated;

  const [profileData, setProfileData] = useState<UserProfileInterface | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sessions, setSessions] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);

  const getUserProfile = useCallback(
    async (userId: string) => {
      setIsLoading(true);
      try {
        const response = await axios.request<UserProfileInterface>({
          url: `${process.env.REACT_APP_CHAT_API}/users/${userId}`,
          method: 'GET',
        });
        setProfileData({ ...response, age: 20, gender: 'male', occupation: 'student' });
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [axios],
  );

  const getSessions = useCallback(
    async (userId?: string, params?: { rowsPerPage?: number; page?: number; includeMetrics?: boolean }) => {
      if (!authenticated) return;
      try {
        setIsLoading(true);
        const queryParams = new URLSearchParams();
        queryParams.append('rowsPerPage', String(params?.rowsPerPage || 25));
        queryParams.append('page', String(params?.page || 0));
        queryParams.append('includeMetrics', String(params?.includeMetrics ?? true));
        if (userId) {
          queryParams.append('user', userId);
        }

        const response = await axios.request<SessionsResponse>({
          method: 'GET',
          url: `${process.env.REACT_APP_CHAT_API}/sessions?${queryParams.toString()}`,
        });
        response.sessions = response.sessions || []
        response.sessions = response.sessions.map((session: SessionDTO) => {
          session.audioAnalyses = generateAudioAnalyses(session.textAnalyses?.length || 0, session.sessionId);
          session.facialAnalyses = generateFacialAnalyses(session.textAnalyses?.length || 0, session.sessionId);
          return session;
        });
        setSessions(response.sessions || []);
        setTotalItems(response.totalRecords || 0);
      } catch (error) {
        console.error('Failed to fetch sessions:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [axios, authenticated],
  );

  return { profileData, getUserProfile, isLoading, sessions, totalItems, getSessions };
};

export default useUserProfile;
