import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import stock from 'highcharts/modules/stock';
import { useParams } from 'react-router-dom';
import useUserProfile from '../../services/hooks/user-profile/useUserProfile';
import { useKeycloak } from '@react-keycloak/web';
import { calculateEmotionalVariability } from '../../utils/evi';
import { calculateOverallMood } from '../../utils/sentiment';
import SessionsList from './sessions/SessionsList';
import LoadingScreen from '../../components/loading/LoadingScreen';

HighchartsMore(Highcharts);
solidGauge(Highcharts);
stock(Highcharts);

const UserProfile = () => {
  const { userId } = useParams();
  const { profileData, getUserProfile, getSessions, sessions } = useUserProfile();
  const { keycloak } = useKeycloak();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedAudioMetric, setSelectedAudioMetric] = useState<string>('speakingRate');
  const [selectedFacialMetric, setSelectedFacialMetric] = useState<string>('blinkRate');

  useEffect(() => {
    const loadProfileData = async () => {
      if (!keycloak.authenticated || !userId) return;
      
      try {
        setIsLoading(true);
        await Promise.all([
          getUserProfile(userId),
          getSessions(userId)
        ]);
      } catch (error) {
        console.error('Error loading profile data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadProfileData();
  }, [userId, keycloak.authenticated, getUserProfile, getSessions]);

  const audioMetricOptions = [
    { value: 'speakingRate', label: 'Speaking Rate' },
    { value: 'averagePitch', label: 'Average Pitch' },
    { value: 'loudness', label: 'Loudness' },
    { value: 'speechDuration', label: 'Speech Duration' },
    { value: 'silenceDuration', label: 'Silence Duration' }
  ];

  const facialMetricOptions = [
    { value: 'blinkRate', label: 'Blink Rate' },
    { value: 'smileRate', label: 'Smile Rate' },
    { value: 'eyeContact', label: 'Eye Contact' }
  ];

  const getMetricUnit = (metric: string) => {
    switch (metric) {
      case 'speakingRate':
        return 'w/m';
      case 'averagePitch':
        return 'Hz';
      case 'loudness':
        return 'dB';
      case 'speechDuration':
      case 'silenceDuration':
        return 's';
      case 'blinkRate':
        return 'b/m';
      case 'smileRate':
      case 'eyeContact':
        return '%';
      default:
        return '';
    }
  };

  const getMetricLabel = (metric: string, isFacial: boolean = false) => {
    const options = isFacial ? facialMetricOptions : audioMetricOptions;
    return options.find(option => option.value === metric)?.label || '';
  };

  const calculateAverageEVI = () => {
    if (!sessions || sessions.length === 0) return 0;
    
    const sessionsWithAnalyses = sessions.filter(session => 
      session.textAnalyses && session.textAnalyses.length > 0
    );
    
    if (sessionsWithAnalyses.length === 0) return 0;
    
    const eviValues = sessionsWithAnalyses.map(session => 
      calculateEmotionalVariability(session.textAnalyses).index
    );
    
    const totalEVI = eviValues.reduce((sum: number, value: number) => sum + value, 0);
    return Math.round(totalEVI / eviValues.length);
  };

  const getEVIGaugeOptions = (value: number) => ({
    chart: {
      type: 'solidgauge',
      height: '300px',
      backgroundColor: 'transparent'
    },
    title: {
      text: 'Average Emotional Variability Index',
      style: {
        fontSize: '16px',
        fontWeight: 'bold'
      }
    },
    subtitle: {
      text: `Based on ${sessions.filter(s => s.textAnalyses?.length > 0).length} sessions`,
      style: {
        fontSize: '12px',
        color: '#666'
      }
    },
    pane: {
      center: ['50%', '65%'],
      size: '100%',
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: '#EEE',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc'
      }
    },
    tooltip: {
      enabled: false
    },
    yAxis: {
      stops: [
        [0.1, '#55BF3B'], // green
        [0.5, '#DDDF0D'], // yellow
        [0.9, '#DF5353']  // red
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      min: 0,
      max: 100,
      title: {
        text: '',
        y: -70
      },
      labels: {
        y: 16
      }
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: -25,
          borderWidth: 0,
          useHTML: true,
          format: '<div style="text-align:center"><span style="font-size:25px;font-weight:bold">{y}</span></div>'
        }
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Average EVI',
      data: [value],
      dataLabels: {
        format: '<div style="text-align:center"><span style="font-size:25px;font-weight:bold">{y}</span></div>'
      }
    }]
  });

  const getSentimentChartOptions = () => {
    // Get sessions with text analyses
    const sessionsWithAnalyses = sessions.filter(session => 
      session.textAnalyses && session.textAnalyses.length > 0
    );

    // Calculate predominant emotion for each session using the utility function
    const sessionSentiments = sessionsWithAnalyses.map(session => 
      calculateOverallMood(session.textAnalyses)
    ).filter(sentiment => sentiment !== '-');

    // Count sessions by predominant sentiment
    const overallSentimentCounts = sessionSentiments.reduce((acc: { [key: string]: number }, sentiment) => {
      acc[sentiment] = (acc[sentiment] || 0) + 1;
      return acc;
    }, {});

    // Convert to Highcharts series data format
    const seriesData = Object.entries(overallSentimentCounts).map(([sentiment, count]) => ({
      name: sentiment,
      y: count,
      color: sentiment === 'Positive' ? '#55BF3B' : 
             sentiment === 'Negative' ? '#DF5353' : 
             '#DDDF0D' // Neutral
    }));

    return {
      chart: {
        type: 'pie',
        height: '300px'
      },
      title: {
        text: 'Overall Session Sentiment Distribution',
        style: {
          fontSize: '16px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: `Based on predominant emotions from ${sessionsWithAnalyses.length} sessions`,
        style: {
          fontSize: '12px',
          color: '#666'
        }
      },
      plotOptions: {
        pie: {
          innerSize: '50%',
          depth: 45,
          dataLabels: {
            enabled: true,
            format: '{point.name} Sessions: {point.percentage:.1f}%'
          }
        }
      },
      series: [{
        name: 'Sessions',
        data: seriesData
      }]
    };
  };

  const getMoodLevelChartOptions = () => {
    if (!sessions) return {};

    // Convert sentiments to numerical values (0-10 scale)
    const sentimentValues = {
      'Positive': 8.5,
      'Neutral': 5.0,
      'Negative': 2.5
    };

    // First filter sessions with textAnalyses and sort them by date
    const validSessions = sessions
      .filter(session => session.textAnalyses && session.textAnalyses.length > 0)
      .sort((a, b) => new Date(a.recordedDate).getTime() - new Date(b.recordedDate).getTime());

    // Process sessions to get average sentiment per session
    const moodData = validSessions.map((session, index) => {
      const analyses = session.textAnalyses || [];
      
      // Calculate average sentiment value for the session
      const totalSegments = analyses.length;
      const moodScore = analyses.reduce((sum: number, analysis: { sentiment: string; }) => {
        // Default to Neutral for unknown sentiments
        const sentimentValue = sentimentValues[analysis.sentiment as keyof typeof sentimentValues] || sentimentValues.Neutral;
        return sum + sentimentValue;
      }, 0);

      const avgSentiment = moodScore / totalSegments;

      // Determine emotion label based on score ranges
      let emotionLabel = 'Neutral';
      if (avgSentiment >= 7) emotionLabel = 'Positive';
      else if (avgSentiment < 4) emotionLabel = 'Negative';

      return {
        x: index,
        y: avgSentiment,
        name: session.name || session.sessionId,
        color: avgSentiment >= 7 ? '#2ecc71' : avgSentiment < 4 ? '#e74c3c' : '#f1c40f',
        date: new Date(session.recordedDate).toLocaleDateString()
      };
    });

    return {
      chart: {
        type: 'spline'
      },
      title: {
        text: 'Mood Over Time',
        style: {
          fontSize: '16px',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        title: {
          text: 'Session Number'
        },
        min: -0.5,
        max: validSessions.length - 0.5,
        tickInterval: 1,
        startOnTick: false,
        endOnTick: false,
        tickmarkPlacement: 'on',
        labels: {
          formatter: function(this: any) {
            const session = validSessions[Math.round(this.value)];
            if (session) {
              const date = new Date(session.recordedDate).toLocaleDateString();
              return `<div style="text-align: center">${Math.round(this.value) + 1}<br/>(${date})</div>`;
            }
            return `<div style="text-align: center">${Math.round(this.value) + 1}</div>`;
          },
          useHTML: true,
          style: {
            fontSize: '11px'
          }
        }
      },
      legend: {
        enabled: false
      },
      yAxis: {
        title: {
          text: 'Mood Level'
        },
        min: 0,
        max: 10,
        tickInterval: 2,
        plotBands: [
          {
            from: 0,
            to: 4,
            color: 'rgba(231, 76, 60, 0.1)',  // Light red
            label: {
              text: 'Negative',
              style: { color: '#e74c3c' }
            }
          },
          {
            from: 4,
            to: 7,
            color: 'rgba(241, 196, 15, 0.1)',  // Light yellow
            label: {
              text: 'Neutral',
              style: { color: '#f1c40f' }
            }
          },
          {
            from: 7,
            to: 10,
            color: 'rgba(46, 204, 113, 0.1)',  // Light green
            label: {
              text: 'Positive',
              style: { color: '#2ecc71' }
            }
          }
        ]
      },
      tooltip: {
        formatter: function(this: any): string {
          return `
            <b>Session ${this.point.x + 1}</b><br/>
            Date: ${this.point.date}<br/>
            Mood Level: ${this.point.y.toFixed(2)} pts`;
        }
      },
      series: [{
        name: 'Mood Level',
        data: moodData
      }]
    };
  };

  const getCandlestickChartOptions = (isFacial: boolean = false) => {
    if (!sessions) return {};

    const validSessions = sessions
      .filter(session => {
        const analyses = isFacial ? session.facialAnalyses : session.audioAnalyses;
        return analyses && analyses.length > 0;
      })
      .sort((a, b) => new Date(a.recordedDate).getTime() - new Date(b.recordedDate).getTime());

    console.log(validSessions);
    const ohlcData = validSessions.map((session, index) => {
      const analyses = isFacial ? session.facialAnalyses : session.audioAnalyses;
      const selectedMetric = isFacial ? selectedFacialMetric : selectedAudioMetric;
      
      const metricValues = analyses.map((analysis: any) => analysis[selectedMetric]);

      const open = metricValues[0] || 0;
      const high = Math.max(...metricValues);
      const low = Math.min(...metricValues);
      const close = metricValues[metricValues.length - 1] || 0;

      return {
        x: index + 1,
        open: open,
        high: high,
        low: low,
        close: close,
        date: new Date(session.recordedDate).toLocaleDateString()
      };
    });

    // Calculate average of all values
    const allValues = ohlcData.reduce((acc, data) => {
      acc.push(data.open, data.high, data.low, data.close);
      return acc;
    }, [] as number[]);
    const average = allValues.reduce((a, b) => a + b, 0) / allValues.length;

    const selectedMetric = isFacial ? selectedFacialMetric : selectedAudioMetric;
    const metricLabel = getMetricLabel(selectedMetric, isFacial);
    const unit = getMetricUnit(selectedMetric);

    return {
      chart: {
        type: 'candlestick',
        height: '400px'
      },
      title: {
        text: `${metricLabel} Variation per Session`,
        style: {
          fontSize: '16px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: `Showing ${metricLabel.toLowerCase()} range within each session`,
        style: {
          fontSize: '12px',
          color: '#666'
        }
      },
      xAxis: {
        title: {
          text: 'Session Number'
        },
        min: 1,
        max: validSessions.length,
        labels: {
          useHTML: true,
          formatter: function(this: any): string {
            const session = validSessions[this.value - 1];
            return session 
              ? `<div style="text-align: center">${this.value}<br/>(${new Date(session.recordedDate).toLocaleDateString()})</div>`
              : `<div style="text-align: center">${this.value}</div>`;
          }
        }
      },
      yAxis: {
        title: {
          text: `${metricLabel} (${unit})`
        },
        gridLineWidth: 1,
        plotLines: [{
          value: average,
          color: '#FF0000',
          dashStyle: 'Dot',
          width: 1,
          label: {
            text: `Average: ${average.toFixed(2)} ${unit}`,
            align: 'right',
            style: {
              color: '#FF0000'
            }
          },
          zIndex: 3
        }]
      },
      tooltip: {
        formatter: function(this: any): string {
          const point = this.point;
          return `
            <b>${point.date}</b><br/>
            ${metricLabel}:<br/>
            Initial: ${point.open.toFixed(2)}&nbsp;${unit}<br/>
            High: ${point.high.toFixed(2)}&nbsp;${unit}<br/>
            Low: ${point.low.toFixed(2)}&nbsp;${unit}<br/>
            Final: ${point.close.toFixed(2)}&nbsp;${unit}
          `;
        }
      },
      plotOptions: {
        candlestick: {
          color: '#3498db', // Blue for down candles
          upColor: '#ffffff', // White for up candles
          lineColor: '#3498db', // Blue lines for all candles
          upLineColor: '#3498db', // Blue lines for up candles
          states: {
            hover: {
              color: '#2980b9', // Darker blue for down candles on hover
              upColor: '#f8f9fa', // Slightly grey white for up candles on hover
              lineColor: '#2980b9', // Darker blue lines on hover
              upLineColor: '#2980b9' // Darker blue lines for up candles on hover
            }
          }
        }
      },
      legend: {
        enabled: false
      },
      series: [{
        name: metricLabel,
        data: ohlcData
      }]
    };
  };

  if (isLoading) {
    return <LoadingScreen 
      title="Loading Profile Data" 
      subtitle="Please wait while we gather user insights and session history"
    />;
  }

  if (!profileData) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <h2 className="text-2xl font-semibold text-gray-700 mb-2">Profile Not Found</h2>
          <p className="text-gray-500">The requested user profile could not be found or you don't have access to it.</p>
        </div>
      </div>
    );
  }

  const averageEVI = calculateAverageEVI();

  return (
    <div className='p-6 w-full h-full bg-white rounded-xl shadow-md space-y-4'>
      <div className='flex justify-between items-center'>
        <div className='flex items-center space-x-4'>
          <img
            src='https://randomuser.me/api/portraits/men/75.jpg'
            alt='Profile'
            className='w-24 h-24 rounded-full'
          />
          <div>
            <h2 className='text-xl font-bold'>
              {profileData.fullName} <span className='text-green-500'>active</span>
            </h2>
            <p className='text-gray-500'>
              {profileData.age} Yrs, {profileData.gender} • Occupation: {profileData.occupation}
            </p>
          </div>
        </div>
        <div className='space-x-2'>
          <button className='bg-blue-500 text-white px-4 py-2 rounded-lg'>
            Initiate New Visit
          </button>
          <button className='bg-blue-500 text-white px-4 py-2 rounded-lg'>Call</button>
          <button className='bg-blue-500 text-white px-4 py-2 rounded-lg'>Message</button>
        </div>
      </div>

      <div className='grid grid-cols-2 gap-4'>
        <div>
          <p className='font-semibold'>Scheduled Appt:</p>
          <p>14 Mar 2021</p>
          <p className='font-semibold'>Special Notes:</p>
          <p>Patient is Deaf. Talk to his wife.</p>
        </div>
        <div>
          <p className='font-semibold'>Address:</p>
          <p>795 Ave Rockland, Outremont, Montreal, Canada</p>
        </div>
      </div>

      <div className='grid grid-cols-3 gap-4'>
        <div className='bg-gray-100 p-4 rounded-lg col-span-1'>
          <HighchartsReact
            highcharts={Highcharts}
            options={getEVIGaugeOptions(averageEVI)}
          />
        </div>
        <div className='bg-gray-100 p-4 rounded-lg'>
          <div className="chart-container">
            <HighchartsReact highcharts={Highcharts} options={getSentimentChartOptions()} />
          </div>
        </div>
        <div className='bg-gray-100 p-4 rounded-lg'>
          <div className="chart-container">
            <HighchartsReact highcharts={Highcharts} options={getMoodLevelChartOptions()} />
          </div>
        </div>
      </div>

      <div className='grid grid-cols-2 gap-4 mt-4'>
        <div className='bg-gray-100 p-4 rounded-lg'>
          <div className="flex justify-end mb-4">
            <select 
              value={selectedAudioMetric}
              onChange={(e) => setSelectedAudioMetric(e.target.value)}
              className="border rounded px-3 py-1 bg-white"
            >
              {audioMetricOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="chart-container">
            <HighchartsReact
              highcharts={Highcharts}
              options={getCandlestickChartOptions(false)}
            />
          </div>
        </div>

        <div className='bg-gray-100 p-4 rounded-lg'>
          <div className="flex justify-end mb-4">
            <select 
              value={selectedFacialMetric}
              onChange={(e) => setSelectedFacialMetric(e.target.value)}
              className="border rounded px-3 py-1 bg-white"
            >
              {facialMetricOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="chart-container">
            <HighchartsReact
              highcharts={Highcharts}
              options={getCandlestickChartOptions(true)}
            />
          </div>
        </div>
      </div>

      <SessionsList userId={userId!}/>
    </div>
  );
};

export default UserProfile;
