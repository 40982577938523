import React, { useCallback, useState, useEffect } from 'react';
import useAxios from '../../services/axios/useAxios';
import { useKeycloak } from '@react-keycloak/web';
import { ViewActions } from './view-actions/ViewActions';
import { useNavigate } from 'react-router-dom';

export interface UsersResponse {
  totalRecords: number;
  users: User[];
}

interface User {
  userId: string;
  email: string;
  fullName: string;
  username: string;
  age?: number;
  gender?: string;
  roles?: [{
    name: string;
  }]
}

const isPatient = (roles?: { name: string }[]): boolean => {
  return roles?.some(role => role.name.toLowerCase() === 'patient') ?? false;
};

const getOccupationFromRoles = (roles?: { name: string }[]): string => {
  if (!roles || roles.length === 0) return 'Manager';
  
  if (roles.some(role => role.name.toLowerCase() === 'patient')) {
    return 'Patient';
  }
  
  if (roles.some(role => role.name.toLowerCase() === 'physician')) {
    return 'Physician';
  }
  
  return 'Manager';
};

const UserCard: React.FC<{ user: User }> = ({ user }) => {
  const navigate = useNavigate();
  const userIsPatient = isPatient(user.roles);

  const handleClick = () => {
    if (userIsPatient) {
      navigate(`/users/${user.userId}/profile`);
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`bg-white rounded-xl shadow-md p-4 flex justify-between items-start hover:bg-gray-50 cursor-pointer transition-colors duration-150 ${
        !userIsPatient 
          ? 'opacity-60 cursor-not-allowed'
          : ''
      }`}
    >
      <div className='flex space-x-4'>
        <img
          src='https://randomuser.me/api/portraits/men/75.jpg'
          alt={user.fullName}
          className={`w-16 h-16 rounded-full ${!userIsPatient ? 'grayscale' : ''}`}
        />
        <div>
          <h3 className='text-lg font-semibold'>
            {user.fullName} <span className='text-green-500 text-sm'>active</span>
          </h3>
          <p className='text-gray-600'>{user.email}</p>
          <p className='text-gray-500 text-sm'>
            {user.age || '29'} Yrs, {user.gender || 'Male'} • {getOccupationFromRoles(user.roles)}
          </p>
        </div>
      </div>
    
    </div>
  );
};

export default function Users() {
  const [usersData, setUsersData] = useState<User[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const axios = useAxios();
  const {
    keycloak: { authenticated },
  } = useKeycloak();

  const fetchData = useCallback(
    async (page = 1) => {
      if (!authenticated) return;
      try {
        setIsLoading(true);
        const queryParams = new URLSearchParams();
        const limit = 10;
        queryParams.append('limit', limit.toString());
        queryParams.append('includeRoles', "true");
        queryParams.append('skip', ((page - 1) * limit).toString());

        const response: UsersResponse = await axios.request({
          method: 'GET',
          url: `${process.env.REACT_APP_CHAT_API}/users?${queryParams.toString()}`,
        });

        setUsersData(response.users);
        setTotalRecords(response.totalRecords);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [axios, authenticated],
  );

  useEffect(() => {
    fetchData(currentPage);
  }, [fetchData, currentPage]);

  if (loading) {
    return (
      <div className='flex justify-center items-center h-64'>
        <div className='text-gray-500'>Loading...</div>
      </div>
    );
  }

  const totalPages = Math.ceil(totalRecords / 10);

  return (
    <div className='space-y-6 p-6'>
      <div className='flex justify-between items-center'>
        <h2 className='text-2xl font-bold'>Users</h2>
        <div className='flex space-x-2'>
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className='px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50'
          >
            Previous
          </button>
          <span className='px-4 py-2 bg-gray-100 rounded-lg'>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className='px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50'
          >
            Next
          </button>
        </div>
      </div>
      <div className='grid gap-4'>
        {usersData.map((user) => (
          <UserCard key={user.userId} user={user} />
        ))}
      </div>
    </div>
  );
}
