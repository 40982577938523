import React from 'react';
import { TextAnalysis } from '../../services/hooks/session/useSession.types';
import dayjs from 'dayjs';

interface TextAnalyticsTableProps {
  textAnalyses: TextAnalysis[];
}

export const TextAnalyticsTable: React.FC<TextAnalyticsTableProps> = ({ textAnalyses }) => {
  const renderCell = (content: string | number | string[] | Date) => {
    const displayContent = Array.isArray(content) 
      ? content.join(', ') 
      : content instanceof Date 
        ? dayjs(content).format('HH:mm:ss')
        : String(content);

    return (
      <div 
        className="max-w-[200px] truncate" 
        title={displayContent}
      >
        {displayContent}
      </div>
    );
  };

  return (
    <div className="bg-white shadow rounded-lg p-6 mb-6">
      <h3 className="text-lg font-semibold mb-4 sticky top-0 bg-white">Text Analytics Data</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky left-0 bg-gray-50">Segment</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start Time</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">End Time</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Primary Emotion</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Extended Emotion</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Emotion Intensity</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Emotion Inference</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Context Description</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Context Keywords</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sentiment</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sentiment Inference</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Emotion Tone</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Attitude Tone</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Style Tone</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Curse Words</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transcript</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {textAnalyses
              .sort((a, b) => a.segment - b.segment)
              .map((analysis, index) => (
                <tr key={analysis.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50 hover:bg-gray-100'}>
                  <td className="px-6 py-4 text-sm text-gray-900 sticky left-0 bg-inherit">{renderCell(analysis.segment)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{renderCell(analysis.startTime)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{renderCell(analysis.endTime)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{renderCell(analysis.primaryEmotion)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{renderCell(analysis.extentedEmotion)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{renderCell(analysis.emotionIntensity)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{renderCell(analysis.primaryEmotionInferenceDescription)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{renderCell(analysis.contextDescription)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{renderCell(analysis.contextKeywords)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{renderCell(analysis.sentiment)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{renderCell(analysis.sentimentInferenceDescription)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{renderCell(analysis.emotionBasedTone)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{renderCell(analysis.attitudeBasedTone)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{renderCell(analysis.styleBasedTone)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{renderCell(analysis.curseWords)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{renderCell(analysis.transcript)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
