import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { AudioPlayerProps } from './AudioPlayer.types';

import './AudioPlayer.scss';
import useSettings from '../../services/hooks/settings/useSettings';
import useDialogue from '../../services/hooks/dialogue/useDialogue';
import dayjs from 'dayjs';

export const AudioPlayer: React.FC<AudioPlayerProps> = ({ audio, message, sessionId }) => {
  const [volume, setVolume] = useState<number>(1);
  let [speechStartedAt, setSpeechStartedAt] = useState<string>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudio, setCurrentAudio] = useState<string>('');

  const audioRef = useRef<HTMLAudioElement>(null);
  const { postDialog } = useDialogue();

  const {
    settings: { showPatientText },
    updateShowChatText,
  } = useSettings();

  const handleVolumeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      try {
        const newVolume = parseFloat(e.target.value);
        if (audioRef.current) {
          audioRef.current.volume = newVolume;
        }
        setVolume(newVolume);
      } catch (e) {
        console.error(e);
      }
    },
    [audioRef],
  );

  useEffect(() => {
    if (!audioRef.current || !message || !sessionId || !speechStartedAt) return;

    const handleEnded = async () => {
      setIsPlaying(false);
      await postDialog({
        text: message || 'no_text',
        speaker: 'bot',
        sessionId,
        speechStartedAt: speechStartedAt,
        speechEndedAt: new Date().toISOString(),
      });
    };

    const audioElement = audioRef.current;
    audioElement.addEventListener('ended', handleEnded);

    return () => {
      audioElement.removeEventListener('ended', handleEnded);
    };
  }, [message, sessionId, speechStartedAt, postDialog]);

  useEffect(() => {
    if (!audioRef.current || !audio || audio === currentAudio) return;

    setCurrentAudio(audio);
    const startTime = new Date().toISOString();
    setSpeechStartedAt(startTime);

    audioRef.current.src = audio;
    audioRef.current.play()
      .then(() => {
        setIsPlaying(true);
      })
      .catch((error) => {
        console.error('Error playing audio:', error);
        setIsPlaying(false);
      });

    return () => {
      if (audioRef.current && isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    };
  }, [audio, currentAudio]);

  return (
    <div className='audio-player'>
      <audio ref={audioRef} src={audio}>
        Your browser does not support the audio element.
      </audio>
      {/*<span>Text to Speech Volume</span>*/}
      {/*<input*/}
      {/*  type='range'*/}
      {/*  min='0'*/}
      {/*  max='1'*/}
      {/*  step='0.01'*/}
      {/*  value={volume}*/}
      {/*  onChange={handleVolumeChange}*/}
      {/*/>*/}
    </div>
  );
};
